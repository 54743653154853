.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  padding-top: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-beige-color);
  min-height: 5vh;
  font-size: calc(10px + 2vmin);
  color: var(--blue-color);
}

.App-header > h1 {
  font-size: calc(10px + 2vmin);
}

.App-menuchoice {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.App-menuchoice > * {
  color: var(--blue-color);
  text-decoration: none;
  border-radius: 3rem;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.1s ease;
}

.App-menuchoice > .is-active {
  font-weight: bold;
}

.App-menuchoice > *:hover {
  background-color: var(--beige-color);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a:link {
  text-decoration: none;
  }
  
  a:visited {
  text-decoration: none;
  }
  
  a:hover {
  text-decoration: none;
  }
  
  a:active {
  text-decoration: none;
  }