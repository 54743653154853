body {
  margin: 0;
  font-family: 'TildaSans', 'brandon grotesque', 
  'abril fatface', 'lato',
  'freight big' , 'futura',
  'silver south', 'gotham',
  'audrey', 'muli',
  'revista black', 'aromatic regular',
  'crimson text', 'pt sans narrow';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-grey-color);
}

* {
  font-family: 'bodoni bauer';
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'brandon grotesque';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --dark-blue-color:#2F4C5B;
  --blue-color:#3C6777;
  --light-blue-color:#68A0B0;
  --beige-color:#EAB6A5;
  --light-beige-color:#EECEC5;
  --light-grey-color:#F5F5F5;

  --turkish-color: #39989D;
}

/* { border: 1px solid red; } */

@font-face {
  font-family: "MAK";
  src: url("/public/MAK.woff") format('woff');
}

@font-face {
  font-family: "MAK-bold";
  src: url("/public/MAK-bold.woff") format('woff');
}

@font-face {
  font-family: "TildaSans";
  src: url("/public/TildaSans-VF.woff2") format('woff');
}
