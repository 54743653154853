.Work-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-content: center;
    justify-content: center;
}

/* .Work-container > div {
    min-width: 10rem;
    min-height: 10rem;
    max-width: 100vw;
} */

.Work-hidden {
    display: none;
}

.Work-slide-container {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.Work-slide-container::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.Work-slide-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.Work-slide {
    max-width: 98vw;
    max-height: 75vh;
}

.Work-slide-skeleton {
    max-width: 98vw;
    height: 0px;
    padding-bottom: 75%;
}

.WorkCover-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 5rem;
}

.WorkCover-header {
    min-width: 10rem;
}

.WorkCover-image {
    filter: blur(2px);
}

.Presentation-image-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    gap: 1rem;
}

@media (min-aspect-ratio: 1/1) {
    .Work-slide-skeleton {
        max-width: 98vw;
        height: 80vh;
        padding-bottom: 0px;
    }
}