
.About-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-grow: 1;
    gap: 1rem;
    margin: 1rem 1rem;
    transition: all 500ms;
}

.About-container > div {
    flex-grow: 1;
    min-height: 10em;
}

.About-profilepic {
    max-width: 90vw;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.About-contact-list {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    flex-wrap: wrap;
    margin-top: 2rem;
    justify-content: center;
    margin-bottom: 15rem;
}

.About-disiplines {
    margin-top: 10rem !important;
}
.About-contact {
    margin-top: 10rem !important;
}

@media screen and (min-width: 900px) {
    .About-container {
        flex-direction: row;
    }

    .About-container > div {
        flex-grow: 1;
        min-height: 88vh;
        width: calc(50vw - 3rem);
    }

    .About-profilepic {
        position: fixed;
        max-width: calc(50vw - 5rem);
        right: 51vw;
        top: 6rem;
    }

    .About-text > div {
        text-align: left;
        margin-left: 1rem;
        margin-top: 10vh !important;
        margin-bottom: 3rem;
    }

    .About-disiplines {
        margin-top: 10rem !important;
    }
    .About-contact {
        margin-top: 10rem !important;
    }
    .About-contact-list {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1200px) {
    .About-profilepic {
        position: fixed;
        max-width: calc(50vw - 5rem);
        right: 51vw;
        top: max(10vh, 4rem);
    }
}