
.Works-container {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 3rem 2rem 0 2rem;
    gap: 1rem;
}

/* .Works-container * {
    text-decoration: initial;
} */

.Works-each-work-container {
    height: 10rem;
    /* transition: all 500ms ease-in-out; */
    border-radius: 0.2rem;
}
/* 
.Works-each-work-container:hover {
    margin-top: -2rem;
    height: 15rem;
    background-color: antiquewhite;
} */

.Works-each-work-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-end;
    justify-content: space-between;
}

/* .Works-each-work-container > * {
    margin-top: auto;
    margin-bottom: auto;
    text-decoration: none;
} */

/* .Works-each-work-container > *:first-child {
    margin-left: 1rem;
} */

/* .Works-each-work-name {
    display: flex;
    align-items: center;
} */

.Works-each-work-name:hover {
    color: #39989D !important;
}

a:link {
text-decoration: none;
}

a:visited {
text-decoration: none;
}

a:hover {
text-decoration: none;
}

a:active {
text-decoration: none;
}